import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { YMaps, Map, Polygon, Polyline, TypeSelector, Panorama, ZoomControl, Placemark } from '@pbe/react-yandex-maps';

import './styles.scss';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setDataPressItem } from '../../store/microdistDataReduces';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { hasDoubleNestedArray, hasNestedArray, hasTripleNestedArray } from '../UI/functions/functions';


const MapComponents = ({ coordinats, styleContainer, mapStyle, modal, onClose, onChange, landplot, activeLandploat, optionsMap, geoObject, isClick, isRedact, isCreateObject }) => {
    const [placemarks, setPlacemarks] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isCreatePin, setIsCreatePin] = useState(false);
    const [drawPolygonCoordinates, setDrawPolygonCoordinates] = useState([]);

    const [draw, setDraw] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [selectedPin, setSelectedPin] = useState(null);
    const microdistData = useSelector((state) => state.mocrodist_data);
    const [lines, setLines] = useState([]);
    const history = useHistory();
    const { id } = useParams();

    const dataCoordCenter = useMemo(() => {
        return activeLandploat !== undefined && activeLandploat.coordinates !== undefined && activeLandploat.coordinates !== null ?
            (activeLandploat.coordinates?.length > 1 ? activeLandploat.coordinates : activeLandploat.coordinates[0]) :
            placemarks;
    }, [activeLandploat, placemarks]);

    const center = useMemo(() => {
        let sumLat = 0;
        let sumLng = 0;

        if (hasDoubleNestedArray(placemarks) && dataCoordCenter.every(coordsData => coordsData.length > 0)) {
            for (const coords of dataCoordCenter) {
                if (coords && coords[0] && coords[0][0] !== undefined && coords[0][1] !== undefined) {
                    sumLat += coords[0][0]; // Широта (latitude)
                    sumLng += coords[0][1]; // Долгота (longitude)
                } else {
                    console.error('Invalid coordinates:', coords);
                }
            }
        } else if (dataCoordCenter.every(coordsData => coordsData.length > 0)) {
            for (const coords of dataCoordCenter) {
                if (coords && coords[0] && coords[0] !== undefined && coords[1] !== undefined) {
                    sumLat += coords[0]; // Широта (latitude)
                    sumLng += coords[1]; // Долгота (longitude)
                } else {
                    console.error('Invalid coordinates:', coords);
                }
            }
        } else {
            console.error('Some elements in dataCoordCenter are empty.');
        }

        return {
            0: sumLat / dataCoordCenter?.length,
            1: sumLng / dataCoordCenter?.length
        };
    }, [dataCoordCenter]);


    const location = useLocation();

    const dispatch = useDispatch();
    useEffect(() => {
        setPlacemarks(coordinats);
        setDrawPolygonCoordinates([]);
        const newLine = [...coordinats];
        setLines((prevLines) => [...prevLines, newLine]);

    }, [coordinats]);


    const handleDrawingAction = (isDraw) => {
        setIsDrawing(isDraw);
        !isDraw &&
            setDrawPolygonCoordinates([])
    };
    const handleEditAction = (isEdit) => {
        setIsEditing(isEdit);
        hasDoubleNestedArray(placemarks) ?
            setDrawPolygonCoordinates([...placemarks, placemarks[0]])
            :
            setDrawPolygonCoordinates([[...placemarks, placemarks[0]]])


        !isEdit &&
            setDrawPolygonCoordinates([])
    };

    const instanceRef = useCallback((ref) => {
        if (ref) {
            ref.editor.startDrawing();
            ref.geometry.events.add('change', (e) => setDrawPolygonCoordinates(e.get('newCoordinates')));

        }
    }, [drawPolygonCoordinates]);



    const handleMapClick = (e) => {
        const coordinates = e.get('coords');
        if (isCreatePin) {
            setSelectedPin(coordinates);
        } else {
            setSelectedPoint(coordinates);
        }
    };

    const handleSaveDrawing = () => {
        const filteredCoordinates = isCreateObject ? selectedPin : drawPolygonCoordinates.filter(coordinates => coordinates.length > 0);

        setPlacemarks([...filteredCoordinates]);
        modal && dispatch(setDataPressItem((id && microdistData.inform_tab) ? ['inform_tab', { ...microdistData.inform_tab, ['coordinates']: filteredCoordinates }] : ['coordinates', filteredCoordinates]));
        setIsDrawing(false);
        onClose();
    };




    return (
        <div className={`mapContainer ${styleContainer}`}>

            <YMaps query={{
                apikey: 'a2718abd-328d-4bd5-a0e1-f246345a30d4',
            }}>
                {dataCoordCenter &&
                    <Map
                        defaultState={{
                            center:
                                (!isNaN(center[0]) && !isNaN(center[1])) ? center : [50.593776, 36.585727],
                            zoom: (!isNaN(center[0]) && !isNaN(center[1])) ? activeLandploat != undefined ? 18 : 14 : 12
                        }}
                        modules={["geoObject.addon.editor", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                        className={`mapStyle ${mapStyle}`}
                        onClick={handleMapClick}

                    >


                        <TypeSelector options={modal ? { float: "left" } : { float: 'right' }} />
                        <ZoomControl options={modal ? { float: "none", position: { top: 100, left: 20 } } : { float: "none", position: { top: 100, right: 20 } }} />

                        {isEditing ?
                            (
                                <Polygon
                                    instanceRef={instanceRef}
                                    geometry={
                                        drawPolygonCoordinates
                                    }
                                    options={{
                                        fillColor: "rgba(255, 158, 0, 0.5)",
                                        strokeColor: "rgba(255, 158, 0, 1)",
                                        opacity: 0.5,
                                        strokeWidth: 3,
                                        strokeStyle: "solid",
                                        editorDrawingCursor: "crosshair",

                                        editingMode: true,
                                    }}
                                    className={'poligon'}
                                />
                            )
                            :
                            dataCoordCenter.length != 0 &&
                            (
                                hasDoubleNestedArray(placemarks) ? <>
                                    {placemarks.map((coords, index) => (
                                        <Polyline key={index} geometry={[...coords, coords[0]]} options={{ strokeColor: '#70707B', strokeWidth: 3 }} />
                                    ))}
                                    {isCreatePin &&
                                        <Placemark geometry={selectedPin} />}
                                </>
                                    :
                                    <>
                                        <Polyline geometry={[...placemarks, placemarks[0]]} options={{ strokeColor: '#70707B', strokeWidth: 3 }} />
                                        {isCreatePin &&
                                            <Placemark geometry={selectedPin} />
                                        }
                                    </>

                            )
                        }


                        {selectedPoint && <Panorama defaultPoint={selectedPoint} />}
                        {isDrawing && (
                            <Polygon
                                instanceRef={instanceRef}
                                geometry={drawPolygonCoordinates}
                                options={{
                                    fillColor: "rgba(255, 158, 0, 0.5)",
                                    strokeColor: "rgba(255, 158, 0, 1)",
                                    opacity: 0.5,
                                    strokeWidth: 3,
                                    strokeStyle: "solid",
                                    editorDrawingCursor: "crosshair",

                                    editingMode: true,
                                }}
                                className={'poligon'}
                            />
                        )}

                        {geoObject && geoObject?.map((item) => {
                            return (
                                <Placemark geometry={item.coordinates} onClick={isClick ? () => {
                                    history.push(`/geoobject/${item.id}`)
                                } : () => { }} properties={{
                                                    hintContent:
                                                        '<div className="tooltipContainer">' +
                                                        `<div>${item.name? item.name: 'название не указано'}</div>` +
                                                        '</div>',
                                                }} />
                            )
                        })}


                        {isCreateObject ?
                            landplot?.map((coord) => {

                                {/* Отрисовка участка в режиме рисования */ }

                                if (coord.coordinates != null) {
                                    let dataCoord = [];
                                    if ((coord.coordinates.length > 1)) {
                                        dataCoord = [...dataCoord, ...coord?.coordinates];
                                    } else {
                                        coord?.coordinates.forEach((item) => {
                                            dataCoord = [...dataCoord, ...item];
                                        });
                                    }
                                    return (
                                        <>

                                            {/* Контур участка */}

                                            <Polyline
                                                geometry={[...dataCoord, dataCoord[0]]}
                                                options={{
                                                    strokeColor: (() => {
                                                        let color;

                                                        // Карта контроля строительства
                                                        if (location.pathname === '/controlsMap') {
                                                            if (coord.is_control_department) {
                                                                color = '#d92d20';
                                                            }
                                                            else {
                                                                switch (coord.stage) {
                                                                    case 'STAGE_1':
                                                                        color = '#66C61C';
                                                                        break;
                                                                    case "STAGE_2":
                                                                        color = '#EAAA08';
                                                                        break;
                                                                    case "STAGE_3":
                                                                        color = '#EF6820';
                                                                        break;
                                                                    case "STAGE_4":
                                                                        color = '#0CC';
                                                                        break;
                                                                    case "STAGE_5":
                                                                        color = '#0CC';
                                                                        break;
                                                                    case "STAGE_6":
                                                                        color = '#70707B';
                                                                        break;
                                                                    default:
                                                                        color = '#000'; // Здесь можно указать значение по умолчанию
                                                                }
                                                            }
                                                        } else {

                                                            // Остальные карты
                                                            switch (coord.status) {
                                                                case 'В работе':
                                                                    if (coord.sub_status === "Социально значимый объект")
                                                                        color = 'rgb(141, 84, 255)';
                                                                    else
                                                                        color = '#2CE';
                                                                    break;
                                                                case 'Первичная бронь':
                                                                    color = '#EAAA08';
                                                                    break;
                                                                case 'В продаже':
                                                                    color = '#66C61C';
                                                                    break;
                                                                case 'Подтвержденная бронь':
                                                                    color = '#EF6820';
                                                                    break;
                                                                case "Реализовано":
                                                                    color = '#70707B';
                                                                    break;
                                                                default:
                                                                    color = '#000'; // Здесь можно указать значение по умолчанию
                                                            }
                                                        }


                                                        return color;
                                                    })(), strokeWidth: 3
                                                }}
                                            >
                                            </Polyline>
                                        </>

                                    )
                                }
                            })
                            :
                            landplot?.map((coord) => {

                                {/* Стандартная отрисовка участка */ }

                                if (coord.coordinates != null) {
                                    let dataCoord = [];
                                    if ((coord.coordinates.length > 1)) {
                                        dataCoord = [...dataCoord, ...coord?.coordinates];
                                    } else {
                                        coord?.coordinates.forEach((item) => {
                                            dataCoord = [...dataCoord, ...item];
                                        });
                                    }
                                    
                                    return (
                                        <>

                                            {/* Заливка участка */}
                                            <Polygon
                                                geometry={[dataCoord]}
                                                options={{
                                                    fillColor: (() => {

                                                        // Карта контроля строительства
                                                        if (location.pathname === '/controlsMap') {
                                                            switch (coord.stage) {
                                                                case 'STAGE_1':
                                                                    return 'rgba(102, 198, 28, 0.50)';
                                                                case "STAGE_6":
                                                                    return 'rgba(112, 112, 123, 0.50)';
                                                                case "STAGE_2":
                                                                    return 'rgba(234, 170, 8, 0.50)';
                                                                case "STAGE_3":
                                                                    return 'rgba(239, 104, 32, 0.50)';
                                                                case "STAGE_4":
                                                                    return 'rgba(34, 204, 238, 0.50)';
                                                                case "STAGE_5":
                                                                    return 'rgba(7, 250, 250, 0.2)';
                                                                default:
                                                                    return 'rgba(255, 255, 255, 0.50)';
                                                            }
                                                        } else {

                                                            // Остальные карты
                                                            switch (coord.status) {
                                                                case 'В работе':
                                                                    if (coord.sub_status === "Социально значимый объект")
                                                                        return 'rgba(141, 84, 255, 0.5)';
                                                                    return 'rgba(34, 204, 238, 0.50)';
                                                                case 'Первичная бронь':
                                                                    return 'rgba(234, 170, 8, 0.50)';
                                                                case 'В продаже':
                                                                    return 'rgba(102, 198, 28, 0.50)';
                                                                case 'Подтвержденная бронь':
                                                                    return 'rgba(239, 104, 32, 0.50)';
                                                                case "Реализовано":
                                                                    return 'rgba(112, 112, 123, 0.50)';
                                                                default:
                                                                    return 'rgba(255, 255, 255, 0.50)';
                                                            }

                                                        }
                                                    })(),

                                                    // Контур участка
                                                    strokeColor: (() => {
                                                        let color;

                                                        // Карта контроля строительства
                                                        if (location.pathname === '/controlsMap') {
                                                            if (coord.is_control_department) {
                                                                color = '#d92d20';
                                                            }
                                                            else {
                                                                switch (coord.stage) {
                                                                    case 'STAGE_1':
                                                                        color = '#66C61C';
                                                                        break;
                                                                    case "STAGE_2":
                                                                        color = '#EAAA08';
                                                                        break;
                                                                    case "STAGE_3":
                                                                        color = '#EF6820';
                                                                        break;
                                                                    case "STAGE_4":
                                                                        color = '#0CC';
                                                                        break;
                                                                    case "STAGE_5":
                                                                        color = '#0CC';
                                                                        break;
                                                                    case "STAGE_6":
                                                                        color = '#70707B';
                                                                        break;
                                                                    default:
                                                                        color = '#000'; // Здесь можно указать значение по умолчанию
                                                                }
                                                            }
                                                        } else {

                                                            // Остальные карты
                                                            switch (coord.status) {
                                                                case 'В работе':
                                                                    if (coord.sub_status === "Социально значимый объект")
                                                                        color = 'rgb(141, 84, 255)';
                                                                    else
                                                                        color = '#2CE';
                                                                    break;
                                                                case 'Первичная бронь':
                                                                    color = '#EAAA08';
                                                                    break;
                                                                case 'В продаже':
                                                                    color = '#66C61C';
                                                                    break;
                                                                case 'Подтвержденная бронь':
                                                                    color = '#EF6820';
                                                                    break;
                                                                case "Реализовано":
                                                                    color = '#70707B';
                                                                    break;
                                                                default:
                                                                    color = '#000'; // Здесь можно указать значение по умолчанию
                                                            }
                                                        }


                                                        return color;
                                                    })(),
                                                    opacity: coord?.cadastral === activeLandploat?.cadastral ? 1 : coord.is_control_department ? 1 : 0.3,
                                                    strokeWidth: coord.is_control_department ? 3 : 1,
                                                    strokeStyle: "solid",
                                                }}
                                                onClick={isClick ? () => {
                                                    history.push(`landplot/${coord.id}`)

                                                } : () => { }}

                                                properties={{
                                                    hintContent:
                                                        '<div className="tooltipContainer">' +
                                                        `<p>Собственник: ${coord.contragent}</p>` +
                                                        `<p>Кадастровый номер: ${coord?.cadastral}</p>` +
                                                        `<p>Статус: ${coord?.status}</p>` +
                                                        `<p>Доп.статус: ${coord?.sub_status}</p>` +
                                                        '</div>',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        background: 'white',
                                                        padding: '2px 4px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #000',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Текст на полигоне: {coord.id}
                                                </div>
                                            </Polygon>
                                            {/* <Placemark
                                        geometry={coord?.coordinates[0]} // Вы можете выбрать, где разместить текст
                                        properties={{
                                            iconContent: `${coord.id}`,
                                        }}
                                        options={{
                                            preset: 'islands#redStretchyIcon',
                                            iconColor: '#0095b6',
                                        }}
                                    /> */}
                                        </>

                                    )
                                }
                            })
                        }
                    </Map>
                }


                {modal && isRedact && (
                    <>

                        {
                            id &&
                            <div className='buttonsEditContainer'>
                                <Buttons
                                    ico={icons.penEdit}
                                    className={`buttonPen ${isEditing ? 'active' : ''}`}
                                    onClick={() => handleEditAction(!isEditing)}
                                />
                            </div>
                        }
                        {
                            isCreateObject ?
                                <div className='buttonsPenContainer'>
                                    <Buttons
                                        ico={icons.penEdit}
                                        className={`buttonPen ${isCreatePin ? 'active' : ''}`}
                                        onClick={() => { setIsCreatePin(!isCreatePin) }}
                                    />
                                </div>
                                :
                                <div className='buttonsPenContainer'>
                                    <Buttons
                                        ico={icons.Pencil}
                                        className={`buttonPen ${isDrawing ? 'active' : ''}`}
                                        onClick={() => handleDrawingAction(!isDrawing)}
                                    />
                                </div>
                        }

                        <div className='buttonsNavMap'>
                            <Buttons
                                className={'closeMapButton'}
                                text={'Отменить'}
                                onClick={() => {
                                    handleDrawingAction(false);
                                    onClose();
                                }}
                            />
                            <Buttons
                                className={'saveMapButton'}
                                text={'Сохранить'}
                                onClick={handleSaveDrawing}
                            />
                        </div>
                    </>
                )}
            </YMaps>

        </div>
    );
};

export default MapComponents;
